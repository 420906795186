<template lang="html">
  <section>
    <audio id="snd" ref="sound-sad" src="../assets/beep-sad.wav" />
    <audio ref="sound-happy" src="../assets/beep-happy.wav" />

    <section v-show="stage === 'front'" class="front">
      <h1>Tyre hotel inventory</h1>
      <button class="btn btn-primary" :disabled="readyState !== 1" @click="start()"> Start </button>
      <div class="ready-state" @click="clickReadyState()">
        <div v-show="readyState === 0"> Waiting for camera... </div>
        <div v-show="readyState === 1"> Camera is ready </div>
        <div v-show="readyState === 2" class="error"> Camera not available </div>
      </div>
      <router-link :to="appendSitePrefix('/barcode-reader')">
        {{ $t('Back') }}
      </router-link>
    </section>

    <qrcode-reader
      v-show="stage === 'scan'"
      :active="scanActive"
      :finish="scanFinished"
      :test-mode="testMode"
      :readers="readers"
      @ready="onReady"
      @decode="onDecode"
    >
      <div class="overlay-contents">
        <div class="position" :class="positionClass">
          <div class="position-name">
            {{ position.name }}
          </div>
          <div v-if="position._id" class="position-contents">
            {{ contentsCount }} expected, {{ scanCount }} scanned
          </div>
        </div>
        <div class="scanned-item" :class="scannedItemClass">
          <i v-if="isLoading" class="fa fa-spin fa-spinner" />
          {{ scannedItem }}
        </div>
        <div class="instruction">
          {{ instruction }}
        </div>
        <div class="actions">
          <button v-show="position._id" class="btn btn-primary" @click="finishScan()"> Finish </button>
          <button v-show="!position._id" class="btn btn-secondary" @click="finishScan()"> Cancel </button>
        </div>
      </div>
    </qrcode-reader>

    <div v-show="stage === 'results'" class="results">
      <div>
        <h1>{{ $t('Finished position') }}</h1>
        <p>
          Position:
          <span v-if="position._id" class="badge badge-success">{{ position.name }}</span>
          <span v-else class="badge badge-danger">No position</span>
        </p>
        <div>
          <button class="btn btn-primary" @click="scanNext()"> Scan another position </button>
          <button class="btn btn-success" @click="doneScanning()"> Done with all positions </button>
        </div>
      </div>
      <table class="table table-sm">
        <tbody>
          <tr v-for="item in position.contents" :key="item.licenseplate" :class="item.found ? 'found' : 'not-found'">
            <td>{{ item.licenseplate }}</td>
            <td>{{ item.description }}</td>
            <td>{{ $t('inventory-action:' + (item.action || 'Remove')) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="stage === 'done'" class="done">
      <h1>Inventory complete</h1>
      <p>
        {{ results.positions }} positions scanned
        <br />
        {{ results.confirmed }} wheels confirmed
        <br />
        {{ results.added }} wheels added
        <br />
        {{ results.removed }} wheels removed
        <br />
      </p>
      <p style="font-weight: bold">
        Inventory number:
        <router-link :to="'/stock/inventory/' + inventoryId + '/stock_inventory/item'">
          {{ inventoryId }}
        </router-link>
      </p>
      <div v-show="!isCommitted">
        <p>
          You can commit the changes to stock right now, or review the changes from your desktop first and commit them
          later.
        </p>
        <button class="btn btn-primary" :disabled="isLoading" @click="commitInventory">
          <i v-if="isLoading" class="fa fa-spin fa-spinner" />
          Commit changes
        </button>
        <router-link :to="{ name: 'Dashboard' }" class="btn btn-secondary" :disabled="isLoading">
          <i v-if="isLoading" class="fa fa-spin fa-spinner" />
          Do not commit yet
        </router-link>
      </div>
      <div v-show="isCommitted">
        <p style="color: green; font-weight: bold"> Changes are now comitted to stock </p>
        <router-link :to="{ name: 'Dashboard' }" class="btn btn-secondary"> Back to front page </router-link>
      </div>
    </div>
  </section>
</template>

<script>
  /*
	 todo:
	 * invert video!
	 * style for viewport with of ipad
	 * if no camera, let user know
	 * take camera on init and turn on/off scanning during
	   now there is delay/bugs when scanning, stopping, scanning again
	 * if scanning the same item twice, say it's already scanned and do not increase foundCount
	 * ignore duplicate scans
	 * handle new item - works but does not show entire summary
	 * update inventory in backend
	 - only scan what is inside sensor rectangle
	 - force portrait mode
	 - refactor
	 - handle type=product (/order) too
	 */

  import { parseBarCode } from './../utils/barCodeParser'
  import { appendSitePrefix } from '@/utils/routeUtils'
  import QrcodeReader from '../components/QrcodeReader'

  export default {
    name: 'InventoryTyrehotel',
    components: {
      QrcodeReader,
    },
    data() {
      return {
        readers: ['qr'],
        inventoryId: null,
        readyState: 0,
        readyStateClicks: 0,
        testMode: false,
        stage: 'front',
        isLoading: false,
        isCommitted: false,
        scanActive: false,
        scanFinished: false,
        scannedItem: '',
        scannedItemClass: '',
        position: {},
        contentsCount: 0,
        confirmCount: 0,
        addCount: 0,
        data: {},
        results: {
          positions: 0,
          confirmed: 0,
          removed: 0,
          added: 0,
        },
      }
    },
    computed: {
      positionClass() {
        return this.position._id ? 'active' : ''
      },
      contentsCouixxxtubant() {
        return this.position._id ? this.position.contents.length : 0
      },
      scanCount() {
        return this.confirmCount + this.addCount
      },
      instruction() {
        if (!this.position._id) {
          return 'Please scan a position'
        }
        return 'Please scan tyre hotel labels'
      },
    },
    methods: {
      appendSitePrefix(url) {
        return appendSitePrefix(url)
      },

      reset() {
        this.position = {}
        this.scannedItem = ''
        this.foundCount = 0
      },

      start() {
        /*
          Playing sounds must be triggered by a user interaction.
          Or rather, _loading_ sounds must be triggered by it.
          After the sound is loaded the first time it can be play()ed from code.
          Loading sounds is triggered by the first play().
          And start() is triggered by user interaction (click button to start inventory).
          So by loading sounds here we work around this limitation, and can
          play the sounds freely on barcode scanning later.
          - stian 201712
        */
        this.$refs['sound-sad'].load()
        this.$refs['sound-happy'].load()
        this.scanActive = true
        this.stage = 'scan'
        this.$axios.post('/v3/stock/inventory').then((response) => {
          this.inventoryId = response.data.data.id
          // console.log('Got inventory id:', this.inventoryId)
        })
      },

      setScanOk(message) {
        this.$refs['sound-happy'].play()
        this.scannedItem = message
        this.scannedItemClass = 'ok'
      },
      setScanError(message, err) {
        this.$refs['sound-sad'].play()
        this.scannedItem = message
        this.scannedItemClass = 'error'
        console.error('setScanError:', message, err)
      },
      setScanReset() {
        this.scannedItem = ''
        this.scannedItemClass = ''
      },

      // position done
      finishScan() {
        if (this.position._id) {
          this.data[this.position._id] = this.position
        }
        this.scanActive = false
        this.stage = 'results'
        this.$axios
          .post(
            '/v3/stock/inventory/' + this.inventoryId + '/position/' + this.position._id + '/contents',
            this.position.contents,
          )
          .then((result) => {
            // console.log('updated contents:', result)
          })
      },

      scanNext() {
        this.reset()
        this.scanActive = true
        this.stage = 'scan'
      },

      // all done
      doneScanning() {
        this.results.positions = 0
        this.results.added = 0
        this.results.confirmed = 0
        this.results.removed = 0
        if (this.data) {
          for (const key in this.data) {
            this.results.positions++
            if (this.data[key].contents) {
              for (let i = 0; i < this.data[key].contents.length; i++) {
                const d = this.data[key].contents[i]
                switch (d.action) {
                case 'Add':
                  this.results.added++
                  break
                case 'Confirmed':
                  this.results.confirmed++
                  break
                default:
                  this.results.removed++
                  break
                }
              }
            }
          }
        }
        this.stage = 'done'
        this.scanFinished = true
      },

      commitInventory() {
        this.isLoading = true
        // console.log('tuba commit')
        this.$axios
          .post('/v3/stock/inventory/' + this.inventoryId + '/commit')
          .then((response) => {
            // console.log('commit ok:', response)
            this.isLoading = false
            this.isCommitted = true
          })
          .catch((err) => {
            console.error('commit err:', err)
            this.isLoading = false
          })
      },

      onReady(err) {
        this.readyState = err ? 2 : 1
      },
      onDecode(content) {
        this.setScanReset()
        const barcode = parseBarCode(content) || {}

        switch (barcode.type) {
        case 'stock-position':
          this.onStockPosition(barcode.id)
          break
        case 'tyre-hotel-line':
          this.onTyreHotelLine(barcode.id)
          break
        default:
          this.setScanError('Unknown barcode')
        }
      },

      onStockPosition(id) {
        if (this.position && this.position._id === id) {
          this.setScanError('Already on this position')
          return
        }
        this.isLoading = true
        this.$refs['sound-happy'].play()
        this.$axios
          .post('/v3/stock/inventory/' + this.inventoryId + '/position/' + id)
          .then((response) => {
            this.position = response.data.data
            this.contentsCount = this.position.contents.length
            this.confirmCount = 0
            this.addCount = 0
            this.setScanReset()
            this.isLoading = false
          })
          .catch((err) => {
            this.setScanError('Unknown position scanned', err)
            this.isLoading = false
          })
      },

      onTyreHotelLine(id) {
        if (!this.position._id) {
          this.setScanError('Scan a position before its contents')
          return
        }
        let done = false
        for (let i = 0; i < this.position.contents.length; i++) {
          if (this.position.contents[i].id === id) {
            done = true
            if (this.position.contents[i].found) {
              this.setScanError('Already scanned')
            } else {
              this.position.contents[i].found = true
              this.position.contents[i].action = 'Confirmed'
              this.setScanOk(this.position.contents[i].description)
              this.confirmCount += 1
            }
          }
        }
        // todo: dupechk, desc/props (axios)
        if (!done) {
          this.isLoading = true
          this.$axios
            .get('/v3/stock/tyre-hotel-line/' + id)
            .then((response) => {
              this.isLoading = false

              const item = response.data.data
              item.found = true
              item.action = 'Add'

              this.position.contents[this.position.contents.length] = item
              this.setScanOk(item.description)
              this.addCount += 1
            })
            .catch((err) => {
              this.setScanError('Unknown tyre hotel item scanned', err)
              this.isLoading = false
            })
        }
      },

      clickReadyState() {
        this.readyStateClicks++
        if (this.readyStateClicks > 10) {
          this.testMode = true
          this.readyState = 1
          // console.log('testmode activated')
        }
      },
    },
  }
</script>

<style lang="sass" scoped>

  .front
  	margin: 10px
  	text-align: center
  	ul
  		text-align: left
  	button
  		padding: 15px
  		width: 200px

  .overlay-contents
  	color: white

  .position
  	height: 30px
  	line-height: 30px
  	display: flex
  	&.active
  		background: green
  	.position-name
  		flex: 1
  		padding-left: 10px
  		font-weight: bold
  	.position-contents
  		flex: 1
  		padding-right: 10px
  		text-align: right
  		font-size: 12px

  .scanned-item
  	margin-top: 5px
  	height: 30px
  	line-height: 30px
  	text-align: center
  	left: -500px
  	&.ok
  		color: green
  	&.error
  		color: red

  .instruction
  	height: 30px
  	line-height: 30px
  	text-align: center
  	i.fa
  		vertical-align: 0

  .actions
  	height: 55px
  	line-height: 50px
  	text-align: center
  	button
  		padding-left: 40px
  		padding-right: 40px

  .results
  	td
  		font-size: 14px
  	tr.found td
  		color: green
  	tr.not-found td
  		color: red
  	h3
  		margin-top: 10px
  		text-align: center
  	> div
  		text-align: center
  		margin-bottom: 20px

  .ready-state
  	font-size: 0.8rem
  	margin-top: 10px
  	margin-bottom: 20px

  .error
  	color: red

  .done
  	padding: 15px
</style>
